var $class="se2--embed-code-survey",$name="EmbedCodeSurvey",$path="app/components/EmbedCodeSurvey/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';

import appendAsyncScriptElement from 'app/utilities/appendAsyncScriptElement';

import defaults from 'lodash/defaults';
import once from 'lodash/once';

export default shell.registerComponent($this, ({ configs, log, mount, schedule }) => {
  mount(
    once((element) => {
      const configuration = {
        surveyActivated: element.dataset.surveyActivated,
        surveyBrandType: element.dataset.surveyBrand,
        surveyCountryCode: element.dataset.surveyCountry,
        surveyIsTest: element.dataset.surveyTestIsflag,
        surveyLangCode: element.dataset.surveyLang,
        surveyLangCountryCode: element.dataset.surveyLangCountry,
        surveyPilot: element.dataset.surveyPilot,
        surveyTestFlag: element.dataset.surveyTestFlag,
        surveyType: element.dataset.surveyType,
        surveyUrl: element.dataset.surveySrc,
      };
      defaults(window, configuration);
      schedule.complete(() => {
        if (configuration.surveyUrl != null) {
          if (configuration.surveyUrl.startsWith('http://') || configuration.surveyUrl.startsWith('https://')
            || configuration.surveyUrl.startsWith('/')) {
            appendAsyncScriptElement(configuration.surveyUrl)
              .catch((error) => log.error('Unable to load survey script: ', error));
          }
        }
      }, configs.delays.s5);
    }),
  );
});
